var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',{staticStyle:{"background":"#f8f9fb"},attrs:{"id":"inspire"}},[_c('v-main',[_c('v-container',{staticClass:"fill-height",attrs:{"fluid":""}},[_c('v-row',{attrs:{"align":"center","justify":"center"}},[_c('v-col',{attrs:{"cols":"12","sm":"8","md":"4"}},[_c('app-header'),_c('v-card',{staticClass:"elevation-3 py-5"},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',{staticClass:"tw-text-center"},[_c('span',{staticClass:"c-blue-text"},[_vm._v("Reset Password")])])],1),_c('v-card-text',{staticClass:"px-10"},[_c('v-form',{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-text-field',{attrs:{"id":"otp","label":"OTP","type":"number","rules":[_vm.required('OTP')]},model:{value:(_vm.otp),callback:function ($$v) {_vm.otp=$$v},expression:"otp"}},[_c('v-icon',{attrs:{"slot":"prepend","color":"primary"},slot:"prepend"},[_vm._v("mdi-security")])],1),_c('v-text-field',{attrs:{"id":"password","label":"Password","name":"new-password","prepend-icon":"mdi-lock","append-icon":_vm.show ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show ? 'text' : 'password',"rules":[
                    _vm.required('password'),
                    _vm.minLength('password', 8),
                    _vm.lowerCaseFormat(),
                    _vm.upperCaseFormat(),
                    _vm.digitFormat(),
                    _vm.specialCharFormat(),
                  ],"counter":"true"},on:{"click:append":function($event){_vm.show = !_vm.show}},model:{value:(_vm.password),callback:function ($$v) {_vm.password=$$v},expression:"password"}}),_c('v-text-field',{attrs:{"id":"confirm-new-password","label":"Confirm password","name":"confirmedPassword","prepend-icon":"mdi-lock","append-icon":_vm.show1 ? 'mdi-eye' : 'mdi-eye-off',"type":_vm.show1 ? 'text' : 'password',"rules":[
                    _vm.required('confirm password'),
                    _vm.confirmPassword('password', _vm.password),
                  ],"counter":"true"},on:{"click:append":function($event){_vm.show1 = !_vm.show1}},model:{value:(_vm.confirmedPassword),callback:function ($$v) {_vm.confirmedPassword=$$v},expression:"confirmedPassword"}})],1)],1),_c('v-card-actions',{staticClass:"px-10"},[_c('v-spacer'),_c('v-btn',{staticClass:"my-2",attrs:{"id":"submit","block":"","large":"","color":"secondary","disabled":!_vm.valid,"loading":_vm.loading},on:{"click":_vm.createPassword}},[_vm._v("Submit Password")])],1),_c('v-card-actions',{staticClass:"justify-center"},[_c('a',{staticClass:"tw-font-bold",attrs:{"id":"otp-resend","href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.resendOtp.apply(null, arguments)}}},[_vm._v(_vm._s(_vm.resend))])]),_c('v-card-actions',{staticClass:"my-1 px-10"},[_c('v-spacer'),_c('span',{staticClass:"grey--text body-2"},[_vm._v("Already have an account ? ")]),_c('v-spacer'),_c('v-btn',{attrs:{"depressed":"","to":"/"}},[_vm._v("Login")])],1)],1)],1)],1)],1)],1),_c('app-bottom')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }